import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHourglass, faSearch } from "@fortawesome/free-solid-svg-icons";
import { search } from "../ajax";

class SearchRSVP extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      searching: false,
      query: "",
      results: [],
      noResults: false,
      selectedParty: null,
    };
  }

  handleChange = (e) => {
    this.setState({
      query: e.target.value,
      results: [],
      selectedParty: null,
      noResults: false,
    });
  };

  handleSubmit = (e) => {
    e.preventDefault();

    const { query } = this.state;

    this.setState({ searching: true }, () => {
      search(query).then((response) => {
        this.setState({
          searching: false,
          results: response,
          noResults: response.length === 0,
        });
      });
    });
  };

  render() {
    const { onSelect } = this.props;
    const { searching, noResults, results, selectedParty } = this.state;

    return (
      <section className="section">
        <div className="container">
          <div className="columns is-centered">
            <div className="column is-8">
              <h1 className="title">RSVP</h1>
              <nav className="panel">
                <p className="panel-heading">Find your invitation</p>
                <form onSubmit={this.handleSubmit}>
                  <div className="panel-block">
                    <div className="control has-icons-left">
                      <div className="field has-addons">
                        <div className="control has-icons-left is-expanded">
                          <input
                            id="search"
                            type="search"
                            className="input has-icons-left"
                            placeholder="Search"
                            autoComplete="off"
                            onChange={this.handleChange}
                          />
                          <span className="icon is-small is-left">
                            <FontAwesomeIcon icon={faSearch} size="xs" />
                          </span>
                        </div>
                        <div className="control">
                          <button type="submit" className="button is-info">
                            Search
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>

                  {searching && (
                    <div className="panel-block">
                      <span className="icon-text">
                        <span className="icon">
                          <FontAwesomeIcon icon={faHourglass} />
                        </span>
                        <span>Searching</span>
                      </span>
                    </div>
                  )}

                  {noResults && (
                    <div className="panel-block">
                      <p>
                        We couldn't find a guest with that name. Try searching
                        with just your last name. If you're still having
                        trouble, let us know!
                      </p>
                    </div>
                  )}

                  {results.length > 0 &&
                    results.map((party) => {
                      return (
                        <div
                          className="panel-block is-clickable py-4"
                          key={party.id}
                          onClick={() =>
                            this.setState({ selectedParty: party })
                          }
                        >
                          <div className="control">
                            <label className="radio">
                              <input
                                id={party.id}
                                type="radio"
                                name="party"
                                checked={
                                  !!(
                                    selectedParty &&
                                    selectedParty.id === party.id
                                  )
                                }
                                onChange={(e) =>
                                  this.setState({ selectedParty: party })
                                }
                              />{" "}
                              {party.name}
                            </label>
                          </div>
                        </div>
                      );
                    })}

                  {results.length > 0 && (
                    <div className="panel-block">
                      <button
                        className="button is-info is-fullwidth"
                        disabled={!selectedParty}
                        onClick={() => onSelect(selectedParty)}
                      >
                        Continue
                      </button>
                    </div>
                  )}
                </form>
              </nav>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

export default SearchRSVP;
