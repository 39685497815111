import React from "react";
import classNames from "classnames";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faSearch,
  faTrashAlt,
  faPlus,
} from "@fortawesome/free-solid-svg-icons";
import { songSearch, getSong } from "../ajax";
import { toast, Slide } from "react-toastify";

const artistNameForTrack = (track) => {
  return track.artists.map((artist) => artist.name).join(", ");
};

const LinkedTrack = ({ track }) => {
  return (
    <a href={track.external_urls.spotify} target="_blank">
      {track.name} - {artistNameForTrack(track)}
    </a>
  );
};

class SongSearch extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      viewState: "view",
      searching: false,
      query: "",
      results: [],
      noResults: false,
      selectedTrack: null,
    };
  }

  componentDidMount() {
    const { trackId } = this.props;

    if (!trackId) {
      return;
    }

    getSong(trackId).then((track) => {
      this.setState({
        selectedTrack: track,
      });
    });
  }

  handleChange = (e) => {
    this.setState({
      query: e.target.value,
      results: [],
      selectedTrack: null,
      noResults: false,
    });
  };

  handleSubmit = (e) => {
    e.preventDefault();

    const { query } = this.state;

    this.setState({ searching: true }, () => {
      songSearch(query).then((response) => {
        this.setState({
          searching: false,
          results: response.tracks.items,
          noResults: response.tracks.total === 0,
        });
      });
    });
  };

  handleSelect = (track) => (e) => {
    e.preventDefault();

    if (/chicken.*dance/i.test(track.name)) {
      toast.error("No chicken dance allowed! 🐓💃🚫", {
        hideProgressBar: true,
        transition: Slide,
        autoClose: 3000,
        closeOnClick: true,
      });

      this.setState({
        results: [],
        query: "",
        noResults: false,
        viewState: "view",
      });

      return;
    }

    this.setState({
      selectedTrack: track,
      results: [],
      query: "",
      noResults: false,
      viewState: "view",
    });
    this.props.onSelect(
      track.id,
      `${track.name} - ${artistNameForTrack(track)}`
    );
  };

  handleCancel = (e) => {
    e.preventDefault();
    this.setState({
      results: [],
      query: "",
      noResults: false,
      viewState: "view",
    });
  };

  handleRemove = (e) => {
    e.preventDefault();
    this.setState({ selectedTrack: null });
    this.props.onRemove();
  };

  render() {
    const { editable } = this.props;

    const { viewState, searching, noResults, results, selectedTrack, query } =
      this.state;

    const searchBtnCls = classNames("button is-info is-small", {
      "is-loading": searching,
    });

    return (
      <>
        {viewState === "view" && selectedTrack && (
          <div className="mb-0">
            <LinkedTrack track={selectedTrack} />

            {editable && (
              <a href="#" onClick={this.handleRemove}>
                <span className="icon-text pl-3">
                  <span className="is-size-7">&nbsp;Remove</span>
                  <span className="icon">
                    <FontAwesomeIcon icon={faTrashAlt} size="xs" />
                  </span>
                </span>
              </a>
            )}
          </div>
        )}

        {viewState === "view" && !selectedTrack && (
          <div className="level mb-0">
            <div className="level-left">
              <span className="level-item">
                <a
                  href="#"
                  onClick={(e) => {
                    e.preventDefault();
                    this.setState({ viewState: "search" });
                  }}
                >
                  <span className="icon-text">
                    <span className="is-size-7">Add</span>
                    <span className="icon">
                      <FontAwesomeIcon icon={faPlus} size="sm" />
                    </span>
                  </span>
                </a>
              </span>
            </div>
          </div>
        )}

        {viewState === "search" && (
          <div className="field has-addons">
            <div className="control has-icons-left is-expanded">
              <input
                id="search"
                type="search"
                className="input has-icons-left is-small"
                placeholder="Search"
                autoComplete="off"
                value={query}
                onChange={this.handleChange}
              />
              <span className="icon is-small is-left">
                <FontAwesomeIcon icon={faSearch} size="sm" />
              </span>
            </div>
            <div className="control">
              <button
                className={searchBtnCls}
                disabled={!query}
                onClick={this.handleSubmit}
              >
                Search
              </button>
            </div>
            <div className="control">
              <button
                className="button is-danger is-small"
                onClick={this.handleCancel}
              >
                Cancel
              </button>
            </div>
          </div>
        )}

        {(noResults || results.length > 0) && (
          <div className="dropdown is-active">
            <div className="dropdown-menu" id="dropdown-menu" role="menu">
              <div className="dropdown-content">
                {noResults && (
                  <div className="dropdown-item">
                    <p>
                      We couldn't find a guest with that name. Try searching
                      with just your last name. If you're still having trouble,
                      let us know!
                    </p>
                  </div>
                )}

                {results.length > 0 &&
                  results.map((track) => {
                    return (
                      <a
                        href="#"
                        className="dropdown-item"
                        key={track.id}
                        onClick={this.handleSelect(track)}
                      >
                        {track.name} - {artistNameForTrack(track)}
                      </a>
                    );
                  })}
              </div>
            </div>
          </div>
        )}
      </>
    );
  }
}

export default SongSearch;
