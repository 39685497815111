import React from "react";
import classNames from "classnames";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faTimesCircle,
  faCheckCircle,
  faArrowLeft,
  faMusic,
  faUtensils,
  faEnvelope,
  faEdit,
  faPlus,
  faUser,
} from "@fortawesome/free-solid-svg-icons";
import { updateRSVP } from "../ajax";
import { toast, Slide } from "react-toastify";
import SongSearch from "./SongSearch";

class Guest extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      saving: false,
      editingEmail: false,
      editingGuestName: false,
      status: props.guest.status,
      vegetarian: props.guest.vegetarian,
      vegan: props.guest.vegan,
      glutenFree: props.guest.glutenFree,
      songRequest: props.guest.songRequest,
      songRequestID: props.guest.songRequestID,
      email: props.guest.email,
      guestName: props.guest.guestName,
    };
  }

  update = (params) => {
    const { guest } = this.props;
    const {
      status,
      vegetarian,
      vegan,
      glutenFree,
      songRequest,
      songRequestID,
      email,
      guestName,
    } = this.state;

    const request = {
      id: guest.id,
      status,
      vegetarian,
      vegan,
      glutenFree,
      songRequest,
      songRequestID,
      email,
      guestName,
      ...params,
    };

    return new Promise((resolve) => {
      this.setState({ saving: true }, () => {
        updateRSVP(request)
          .then((response) => {
            this.setState({
              status: response.status,
              vegetarian: response.vegetarian,
              vegan: response.vegan,
              glutenFree: response.glutenFree,
              songRequest: response.songRequest,
              songRequestID: response.songRequestID,
              email: response.email,
              guestName: response.guestName,
              saving: false,
            });

            toast.success("Your RSVP has been saved 🎉", {
              hideProgressBar: true,
              transition: Slide,
              autoClose: 3000,
              closeOnClick: true,
            });

            resolve();
          })
          .catch((e) => {
            this.setState({
              saving: false,
            });

            toast.error(
              "Sorry, there was an issue updating your RSVP. Please try again.",
              {
                hideProgressBar: true,
                transition: Slide,
                autoClose: 3000,
                closeOnClick: true,
              }
            );
          });
      });
    });
  };

  handleChangeDiet = (e) => {
    this.update({
      [e.target.getAttribute("name")]: e.target.checked,
    });
  };

  handleChangeStatus = (status) => () => {
    this.update({
      status,
    });
  };

  handleChangeSong = (trackId, trackName) => {
    this.update({
      songRequest: trackName,
      songRequestID: trackId,
    });
  };

  handleRemoveSong = () => {
    this.update({
      songRequest: "",
      songRequestID: "",
    });
  };

  handleChangeEmail = (e) => {
    this.setState({ email: e.target.value });
  };

  handleSubmitEmail = () => {
    this.update({
      email: this.state.email,
    }).then(() => {
      this.setState({ editingEmail: false });
    });
  };

  handleChangeGuestName = (e) => {
    this.setState({ guestName: e.target.value });
  };

  handleSubmitGuestName = () => {
    this.update({
      guestName: this.state.guestName,
    }).then(() => {
      this.setState({ editingGuestName: false });
    });
  };

  renderGuestName() {
    if (this.props.guest.name != "Guest") {
      return null;
    }
    const { saving, editingGuestName, guestName } = this.state;

    const saveGuestNameCls = classNames("button is-info", {
      "is-loading": saving,
    });

    return (
      <>
        <legend className="label">
          <span className="icon-text">
            <span>Guest Name</span>
            <span className="icon">
              <FontAwesomeIcon icon={faUser} />
            </span>
          </span>
        </legend>

        {editingGuestName ? (
          <div className="field has-addons">
            <div className="control has-icons-left is-expanded">
              <input
                id="guestName"
                type="text"
                className="input has-icons-left"
                placeholder="Jenny Fields"
                autoComplete="off"
                value={guestName}
                onChange={this.handleChangeGuestName}
                disabled={saving}
              />
              <span className="icon is-small is-left">
                <FontAwesomeIcon icon={faUser} size="sm" />
              </span>
            </div>
            <div className="control">
              <button
                className={saveGuestNameCls}
                disabled={saving}
                onClick={this.handleSubmitGuestName}
              >
                Save
              </button>
            </div>
            <div className="control">
              <button
                className="button is-danger"
                disabled={saving}
                onClick={(e) => {
                  this.setState({
                    editingGuestName: false,
                    guestName: this.props.guest.guestName,
                  });
                }}
              >
                Cancel
              </button>
            </div>
          </div>
        ) : (
          <div className="field">
            {guestName}
            <a
              href="#"
              onClick={(e) => {
                this.setState({ editingGuestName: true });
                e.preventDefault();
              }}
            >
              {guestName ? (
                <span className="icon-text pl-3">
                  <span className="is-size-7">Change</span>
                  <span className="icon">
                    <FontAwesomeIcon icon={faEdit} size="xs" />
                  </span>
                </span>
              ) : (
                <span className="icon-text">
                  <span className="is-size-7">Add</span>
                  <span className="icon">
                    <FontAwesomeIcon icon={faPlus} size="xs" />
                  </span>
                </span>
              )}
            </a>
          </div>
        )}
      </>
    );
  }

  render() {
    const { guest } = this.props;
    const { status, saving, editingEmail } = this.state;

    const isExpanded = status === "ATTENDING";

    const acceptedCls = classNames("button is-small", {
      "is-success": status === "ATTENDING",
    });

    const declinedCls = classNames("button is-small", {
      "is-danger": status === "DECLINED",
    });

    const levelCls = classNames("level is-small", {
      "mb-0": !isExpanded,
    });

    const saveEmailCls = classNames("button is-info", {
      "is-loading": saving,
    });

    return (
      <div className="block">
        <div className="card">
          <div className="card-content">
            <div className={levelCls}>
              <div className="level-left">
                <div className="level-item">
                  <p className="title is-5">{guest.name}</p>
                </div>
              </div>
              <div className="level-right">
                <div className="field is-grouped">
                  <div className="control">
                    <button
                      disabled={saving}
                      className={acceptedCls}
                      onClick={this.handleChangeStatus("ATTENDING")}
                    >
                      <span className="icon is-small">
                        <FontAwesomeIcon icon={faCheckCircle} />
                      </span>
                      <span>Attending</span>
                    </button>
                  </div>
                  <div className="control">
                    <button
                      disabled={saving}
                      className={declinedCls}
                      onClick={this.handleChangeStatus("DECLINED")}
                    >
                      <span className="icon is-small">
                        <FontAwesomeIcon icon={faTimesCircle} />
                      </span>
                      <span>Declined</span>
                    </button>
                  </div>
                </div>
              </div>
            </div>

            <form onSubmit={(e) => e.preventDefault()}>
              {isExpanded && (
                <fieldset className="fieldset">
                  <legend className="label">Details</legend>

                  {this.renderGuestName()}

                  <legend className="label">
                    <span className="icon-text">
                      <span>Email</span>
                      <span className="icon">
                        <FontAwesomeIcon icon={faEnvelope} />
                      </span>
                    </span>
                  </legend>

                  {editingEmail ? (
                    <div className="field has-addons">
                      <div className="control has-icons-left is-expanded">
                        <input
                          id="email"
                          type="text"
                          className="input has-icons-left"
                          placeholder="jenny.fields@example.com"
                          autoComplete="off"
                          value={this.state.email}
                          onChange={this.handleChangeEmail}
                          disabled={saving}
                        />
                        <span className="icon is-small is-left">
                          <FontAwesomeIcon icon={faEnvelope} size="sm" />
                        </span>
                      </div>
                      <div className="control">
                        <button
                          className={saveEmailCls}
                          disabled={saving}
                          onClick={this.handleSubmitEmail}
                        >
                          Save
                        </button>
                      </div>
                      <div className="control">
                        <button
                          className="button is-danger"
                          disabled={saving}
                          onClick={(e) => {
                            this.setState({
                              editingEmail: false,
                              email: this.props.guest.email,
                            });
                          }}
                        >
                          Cancel
                        </button>
                      </div>
                    </div>
                  ) : (
                    <div className="field">
                      {this.state.email}
                      <a
                        href="#"
                        onClick={(e) => {
                          this.setState({ editingEmail: true });
                          e.preventDefault();
                        }}
                      >
                        {this.state.email ? (
                          <span className="icon-text pl-3">
                            <span className="is-size-7">Change</span>
                            <span className="icon">
                              <FontAwesomeIcon icon={faEdit} size="xs" />
                            </span>
                          </span>
                        ) : (
                          <span className="icon-text">
                            <span className="is-size-7">Add</span>
                            <span className="icon">
                              <FontAwesomeIcon icon={faPlus} size="xs" />
                            </span>
                          </span>
                        )}
                      </a>
                      <p className="help">
                        (Optional) We may use this to let you know about
                        important updates.
                      </p>
                    </div>
                  )}

                  <legend className="label">
                    <span className="icon-text">
                      <span>Dietary restrictions</span>
                      <span className="icon">
                        <FontAwesomeIcon icon={faUtensils} />
                      </span>
                    </span>
                  </legend>
                  <div className="field mb-0">
                    <label className="checkbox">
                      <input
                        type="checkbox"
                        name="vegetarian"
                        disabled={saving}
                        checked={this.state.vegetarian}
                        onChange={this.handleChangeDiet}
                      />{" "}
                      Vegetarian
                    </label>
                  </div>
                  <div className="field mb-0">
                    <label className="checkbox">
                      <input
                        type="checkbox"
                        name="vegan"
                        disabled={saving}
                        checked={this.state.vegan}
                        onChange={this.handleChangeDiet}
                      />{" "}
                      Vegan
                    </label>
                  </div>
                  <div className="field">
                    <label className="checkbox">
                      <input
                        type="checkbox"
                        name="glutenFree"
                        disabled={saving}
                        checked={this.state.glutenFree}
                        onChange={this.handleChangeDiet}
                      />{" "}
                      Gluten free
                    </label>
                    <p className="help">
                      (Optional) Feel free to leave this blank if you don't have
                      any restrictions.
                    </p>
                  </div>

                  <div className="field">
                    <label className="label">
                      <span className="icon-text">
                        <span>Song request</span>
                        <span className="icon">
                          <FontAwesomeIcon icon={faMusic} />
                        </span>
                      </span>
                    </label>
                    <SongSearch
                      editable
                      trackId={this.state.songRequestID}
                      onSelect={this.handleChangeSong}
                      onRemove={this.handleRemoveSong}
                    />
                    <p className="help">
                      (Optional) Choose a song for us to include on the
                      playlist!
                    </p>
                  </div>
                </fieldset>
              )}
            </form>
          </div>
        </div>
      </div>
    );
  }
}

const EditRSVP = ({ party, onBack }) => {
  return (
    <div className="container">
      <div className="columns is-centered">
        <div className="column is-8">
          <h1 className="title">RSVP</h1>

          <div className="block">
            <a
              href="#"
              onClick={(e) => {
                e.preventDefault();
                onBack();
              }}
            >
              <span className="icon-text">
                <span className="icon">
                  <FontAwesomeIcon icon={faArrowLeft} />
                </span>
                <span>Back to search</span>
              </span>
            </a>
          </div>

          {party.guests.map((guest) => {
            return <Guest key={guest.id} guest={guest} />;
          })}
        </div>
      </div>
    </div>
  );
};

export default EditRSVP;
