import React from "react";
import Layout from "../../components/Layout";
import EditRSVP from "../../components/EditRSVP";
import SearchRSVP from "../../components/SearchRSVP";

export default class Respond extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      viewState: "searching",
      query: "",
      party: null,
    };
  }

  renderContent() {
    const { viewState, party } = this.state;

    switch (viewState) {
      case "searching":
        return (
          <SearchRSVP
            onSelect={(party) => this.setState({ party, viewState: "editing" })}
          />
        );
      case "editing":
        return (
          <EditRSVP
            party={party}
            onBack={() => this.setState({ viewState: "searching" })}
          />
        );
      default:
        return null;
    }
  }

  render() {
    return (
      <Layout title="RSVP">
        <section className="section">{this.renderContent()}</section>
      </Layout>
    );
  }
}
